import SwiperCore, {Navigation, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import heroSliderData from './heroSliderData';
import HeroSliderItem from './HeroSliderItem';

SwiperCore.use([Navigation, Autoplay]);

function Hero() {
    console.log(heroSliderData);
    return (
        <div className="hero-wrapper">
            <div className="hero-slider-wrapper">
                <Swiper navigation loop autoplay={{delay: 7000}} speed={800}>
                    {heroSliderData.map((data) => (
                        <SwiperSlide key={data.id}>
                            <HeroSliderItem
                                shadowHeading={data.shadowHeading}
                                mainHeading={data.mainHeading}
                                subHeading={data.subHeading}
                                subParagraph={data.subParagraph}
                                img={data.img}
                                btnText={data.btnText}
                                btnLink={data.btnLink}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default Hero;
