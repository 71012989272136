import { v4 as uuidv4 } from 'uuid';

// Function for fetching all images in a directory: returns an array
function importAll(r) {
  let images = [];
  r.keys().map(item => images.push(r(item)));
  return images;
}

const realisatiesData = [
  {
    id: uuidv4(),
    name: "Project Riemst",
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Riemst', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Kurago - Hasselt',
    subTitle: 'Particuliere projectbouw',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Kurago', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Apotheek - Maasmechelen',
    subTitle: 'Particuliere projectbouw',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/MaasmechelenApotheek', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Appartementen - Zutendaal',
    subTitle: 'Particuliere projectbouw',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/ZutendaalAppartementen', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Woning - Linter',
    subTitle: 'Particuliere woningbouw ',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/WoningLinter', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Courtessem - Kortessem',
    subTitle: 'Projectbouw - Woonwijk',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Courtessem', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Godskespark',
    subTitle: 'Projectbouw - Woonwijk',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Godskespark', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Has’oh – Hasselt',
    subTitle: 'Projectbouw - Appartementen',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Hasoh', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Hommelhof  – Hasselt',
    subTitle: 'Projectbouw - Woonwijk',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Hommelhof', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Wings – Bevingen',
    subTitle: 'Projectbouw - Appartementen',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/Wings', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Appartementen – Lommel',
    subTitle: 'Projectbouw - Appartementen ',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/LommelAppartementen', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Woning – Genk',
    subTitle: 'Particuliere woningbouw',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/GenkWoning', false, /\.(png|jpe?g|svg)$/))
  },
  {
    id: uuidv4(),
    name: 'Project Bievelenhof – Bilzen',
    subTitle: 'Projectbouw - Appartementen',
    images: importAll(require.context('../../assets/images/DBA/Realisaties/BilzenBievelenhof', false, /\.(png|jpe?g|svg)$/))
  },
];

export default realisatiesData;
