import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/images/DBA/Hero/dba_hero1.jpg';
import img2 from '../../assets/images/DBA/Hero/dba_hero-bilzen.jpg';
import img3 from '../../assets/images/DBA/Hero/dba_hero3.jpg';
import img4 from '../../assets/images/DBA/Hero/dba_hero-bevingen.jpg';

const heroSliderData = [
  {
    id: uuidv4(),
    shadowHeading: '',
    mainHeading: 'Over ons',
    subHeading: 'Al van kleins af aan actief in de bouw!',
    subParagraph:
      'De ervaringen die wij doorheen de jaren hebben opgebouwd, maakt ons nu tot betrouwbare partner voor zowel professionele als particuliere klanten.',
    img: img1,
    btnText: 'Lees meer',
    btnLink: '/overons',
  },

  {
    id: uuidv4(),
    shadowHeading: '',
    mainHeading: 'Diensten',
    subHeading: 'Bij ons kan u steeds terecht!',
    subParagraph:
      'Of het nu gaat over een bouw- of renovatieproject, particulier of professioneel, woning- of kelderbouw, ...',
    img: img2,
    btnText: 'Lees meer',
    btnLink: '/#diensten',
  },
  {
    id: uuidv4(),
    shadowHeading: '',
    mainHeading: 'Realisaties',
    subHeading: 'Wij zijn trots op onze projecten!',
    subParagraph: 'Neem zelf maar eens een kijkje tussen onze realisaties',
    img: img3,
    btnText: 'Lees meer',
    btnLink: '/realisaties',
  },

  {
    id: uuidv4(),
    shadowHeading: '',
    mainHeading: 'Vacatures',
    subHeading: 'Werken bij DBA Onderneming?',
    subParagraph:
      'Welkom in ons team, waar er steeds een gezellige en goede groepssfeer is.',
    img: img4,
    btnText: 'Lees meer',
    btnLink: '/vacatures',
  },
];

export default heroSliderData;
