import bannerBg from '../assets/images/DBA/PageHeaders/header_realisaties-bevingen-3.jpg';
import PageBanner from '../components/PageBanner';
import PortfolioProject from '../components/PortfolioProject';

function Portfolio() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Realisaties"
        mainHeading="Realisaties"
        currentPage="Realisaties"
      />
      <PortfolioProject />
    </>
  );
}

export default Portfolio;
